import React, { useEffect, useState, useMemo } from 'react';
import './styles/Spinner.css';
import './styles/Slider.css';

import logo from './logo/ARC.png';
import { DataSet } from 'vis-data';
import GraphNetwork from './NetworkGraph'; // Import the GraphNetwork component
import ChatInterface from './Interface'; // Import the Chat component

//import ChatInterface from './ChatInterface'; // Import the ChatInterface component
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0 for Auth0 authentication functions

function App() {

  const [nodes, setNodes] = useState(new DataSet([]));
  const [edges, setEdges] = useState(new DataSet([]));
  const [distances, setDistances] = useState({});
  const [selectedDepth, setSelectedDepth] = useState(3); // Initialize with depth 3
  const [maxDepth, setMaxDepth] = useState(5); // Initialize with a default maximum depth
  const [highlightedNodeIds, setHighlightedNodeIds] = useState([]);
  const memoizedHighlightedNodeIds = useMemo(() => highlightedNodeIds, [highlightedNodeIds]);
  const updateHighlightedNodeIds = (nodeIds) => {
      setHighlightedNodeIds(nodeIds);
  };
  const onSetSelectedDepth = (depth) => {
    setSelectedDepth(depth);
  };

  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();
  // Automatically trigger login if not authenticated
  console.log("Welcome, ", user);
  console.log('url',window.location.hostname)
  const isProduction = window.location.hostname === "www.untitled-arc.com";

  // Only trigger login redirect in production if authentication check has completed and user is not authenticated
  useEffect(() => {
  if (isProduction && !isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);



  

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };
  
    useEffect(() => {
    // Fetch the graph data (nodes and edges)
    fetch('https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/get-graph-data')
      .then(response => response.json())
      .then(data => {
        setNodes(new DataSet(data.nodes));
        console.log('Nodes data:', data.nodes);
        setEdges(new DataSet(data.edges));
        console.log('Edges data:', data.edges);
      })
      .catch(error => {
        console.error('Error fetching graph data:', error);
      });

    // Fetch the distances data
    fetch('https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/get-graph-distance')
      .then(response => response.json())
      .then(data => {
        setDistances(data);
        console.log('Distances data:', data);
        const maxDistance = Math.max(...Object.values(data));
        console.log('Max distance:', maxDistance);
        setMaxDepth(maxDistance);
        if (maxDistance < 3) {
          setSelectedDepth(maxDistance);
        }
      })
      .catch(error => {
        console.error('Error fetching distances data:', error);
      });
  }, []);

  const filteredGraphData = useMemo(() => {
    const filteredNodes = nodes.get({
      filter: (node) => distances[node.id] <= selectedDepth,
    });

    let filteredEdges = edges.get({
      filter: (edge) =>
        filteredNodes.some((node) => node.id === edge.from) &&
        filteredNodes.some((node) => node.id === edge.to),
    });

    const edgeSet = new Set();
    filteredEdges = filteredEdges.filter(edge => {
      const edgeKey = `${edge.from}-${edge.to}`;
      if (edgeSet.has(edgeKey)) {
        return false;
      } else {
        edgeSet.add(edgeKey);
        return true;
      }
    });

    return { nodes: new DataSet(filteredNodes), edges: new DataSet(filteredEdges) };
  }, [nodes, edges, distances, selectedDepth]);

  const GraphNetworkMemo = React.memo(GraphNetwork);

return(

<div className="App">
<header className="App-header">
  <img src={logo} className="App-logo" alt="logo" onClick={handleLogout} />
    <div className="filter-bar">
    <input
            type="range"
            min={1}
            max={maxDepth}
            value={selectedDepth}
            onChange={(e) => setSelectedDepth(parseInt(e.target.value))}
          />
          <span>{selectedDepth}</span>
    </div>

</header>
<div className="main-content"> {/* Flex container for main content */}
  <div className="graph-container">
    <GraphNetworkMemo
      nodes={filteredGraphData.nodes}
      edges={filteredGraphData.edges}
      highlightedNodeIds={memoizedHighlightedNodeIds}
    />
  </div>
  <div className="chat-interface">
    <ChatInterface
    updateHighlightedNodes={updateHighlightedNodeIds}
    onSetSelectedDepth={setSelectedDepth}

    />
  </div>
</div>
</div>

);

}

export default App;
