import React, { useState, useRef, useEffect } from 'react';
import './styles/ChatSession.css';
import './styles/dots.css';
import './styles/Button.css';
import ReactMarkdown from 'react-markdown';


import { useAuth0 } from '@auth0/auth0-react';

function ChatSession({ id, updateHighlightedNodes, onSetSelectedDepth }) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // conversation history loading

  const inputRef = useRef(null);
  const { user: authUser, isAuthenticated } = useAuth0();


  const isProduction = window.location.hostname === "www.untitled-arc.com";
  const user = isProduction && isAuthenticated 
                                                                                                   ? authUser 
                                                                                                   : { given_name: "Guest", sub: "guest_user", family_name: "User",
                                                                                                       user: "guest_user", nickname: "guest_user", picture: 'none',
                                                                                                       locale: 'en', email: 'none', email_verified: false, sub: 'xxx' };
                                           

  const messagesEndRef = useRef(null);

  const updateLastUsed = async () => {
    try {

      const response = await fetch(`https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/update-last-used`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_id: id,
          user: user
        }),
      });
  
    } catch (error) {
      console.error('Error updating table:', error);
    }

  }
  
  
  const fetchChatHistory = async () => {
    setIsLoading(true); // Start loading

    try {
      const response = await fetch(`https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/get-saved-message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_id: id,
          user: user
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.status && data.status === 'No history found') {
          console.log('No history found');
        } else {
          const formattedMessages = Object.values(data).map(msg => ({
            text: msg.text,
            username: msg.user, // Map 'user' from API to 'username' in the state
            loading: false
          }));
          setMessages(formattedMessages);
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
    setIsLoading(false); // End loading

  };
  
  useEffect(() => {
    fetchChatHistory();
    updateLastUsed();
  }, [id]);
  

  useEffect(() => {
    const handleFocus = () => {
      const inputEl = inputRef.current;
      setTimeout(() => {
        inputEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 300);
    };

    const inputEl = inputRef.current;
    inputEl.addEventListener('focus', handleFocus);
    return () => inputEl.removeEventListener('focus', handleFocus);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = async (text) => {
    if (text.trim() === '') {
      // If the text is empty, do nothing
      return;
    }
  
    console.log('user:', user);
    console.log('id:', id);
    const newMessage = {
      text,
      username: user.given_name,
      loading: true
      
    };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    
    // Save the message to the database
    try {
      const response = await fetch('https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/save-message', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          text: newMessage, 
          user: user, 
          chat_id: id, 
          date: Date.now()}),
      });

    } catch (error) {
      console.error('Error saving message:', error);
    }

    try {
      const response = await fetch('https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/answer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ messages: messages.concat(newMessage), user: user, ChatId: id, date: Date.now()}),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.taskId) {
        checkTaskStatus(data.taskId, newMessage.id);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => prevMessages.map(msg =>
        msg.id === newMessage.id ? { ...msg, loading: false } : msg
      ));
    }
  };

  const checkTaskStatus = async (taskId, messageId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/get-result?taskId=${taskId}`);
        const data = await response.json();
        if (data.status === 'completed') {
          clearInterval(intervalId);
          console.log('visual:', data.visual)
          updateVisualData(data.visual, messageId);  // Handle visual data
          const newMessage = {
            id: Date.now(),
            text: data.result || "No response received",
            username: "Arc",
            loading: false
          };

          setMessages(prevMessages => prevMessages.map(msg =>
            msg.id === messageId ? { ...msg, loading: false } : msg

          
          ).concat(newMessage));
          console.log('messages',messages)
          // save the message to the database
          try {
                      const response = await fetch('https://xcvpx7a95h.execute-api.eu-central-1.amazonaws.com/dev/save-message', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                          text: data.result, 
                          user: user, 
                          chat_id: id, 
                          date: Date.now()}),
                      });
                    } catch (error) {
                      console.error('Error saving message:', error);
                    }
          
          
          
        }
      } catch (error) {
        console.error('Error checking task status:', error);
        clearInterval(intervalId);
        setMessages(prevMessages => prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, loading: false } : msg
        ));
        
      }
    }, 1000);
  };

  const updateVisualData = (visual, messageId) => {
    try {
        let parsedVisual = JSON.parse(visual.trim());
        if (typeof parsedVisual === 'string') {
            parsedVisual = JSON.parse(parsedVisual);
        }

        // Directly call updateHighlightedNodes with new highlighted node IDs
        if (parsedVisual.nodes_to_highlight) {
            updateHighlightedNodes(parsedVisual.highlight_nodes);
        }

        onSetSelectedDepth(parsedVisual.level);

    } catch (error) {
        console.error('Error parsing visual data:', error);
    }
};

  return (
    <div className="chat-session">
      <button
        className="button-17"
        style={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={() => sendMessage(`Hello my name is ${user && user.given_name ? user.given_name : "there"}! Can you please tell me what you can do? Give me some example questions?`)}
      >
        ?
      </button>

      <div className="messages">
        {messages.map(msg => (
          <div key={msg.id} className={`message ${msg.username === "Arc" ? "message.ai" : "message.me"}`}>
            <div className="message-content-container">
              <div className="message-header">{msg.username}</div>
              <div className="message-content" style={{ whiteSpace: 'pre-wrap' }}>
                <ReactMarkdown>{msg.text}</ReactMarkdown>
              </div>
              {msg.loading && <div className="loading-dots"><div></div><div></div><div></div></div>}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-container">
        <input
          ref={inputRef}
          type="text"
          placeholder={`Hey ${user && user.given_name ? user.given_name : "there"}! Type a message...`}
          className="chat-input"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendMessage(e.target.value);
              e.target.value = '';
            }
          }}
        />
      <button
        className="send-button"
        onClick={() => {
          const inputValue = inputRef.current.value;
          if (inputValue.trim() !== '') {
            sendMessage(inputValue);
            inputRef.current.value = '';
          }
        }}
        disabled={isLoading} // Disable button while loading
      >
        Send
      </button>
      </div>
    </div>
  );
}

export default ChatSession;
